<template>
  <div class="heroLanding-container">
    <img 
      :src="getImagePath" 
      :alt="pageName + ' hero image'" 
      class="heroLanding-image"
    >
    <button v-if="pageName.toLowerCase() === 'guardias' || pageName.toLowerCase() === 'intendencia'"
    class="heroLanding-button" 
    :class="{'right': pageName === 'guardias' }"
    @click="navigateToLanding">Ver más</button>
  </div>
</template>

<script>
export default {
  name: 'HeroLandingPageComponent',
  props: {
    pageName: {
      type: String,
      required: true
    }
  },
  computed: {
    getImagePath() {
      return require(`@/assets/landingPages/${this.pageName.toLowerCase()}.svg`);
    }
  },
  methods: {
    navigateToLanding() {
      this.$router.push({ path: `/${this.pageName.toLowerCase()}` }); 
    }
  }
}
</script>

<style scoped>
.heroLanding-container {
  position: relative;
  width: 100%;
  line-height: 0; 
  font-size: 0; 
}

.heroLanding-image {
  width: 100%;
  height: auto;
  display: block; 
  object-fit: contain; 
}

.heroLanding-button {
  position: absolute;
  bottom: 35%;
  left: 25%;
  padding: 10px 20px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.heroLanding-button.right{
  left: 65%;
}

.contact-button:hover {
  background-color: #fff;
  color: #002169;
}

/* Responsive adjustments */

/* Small devices (phones) */
@media (max-width: 575.98px) {
  .heroLanding-container {
    max-height: 400px; /* Incrementamos el tamaño para móviles */
  }
  .heroLanding-image {
    max-height: 400px;
  }
  .heroLanding-button {
    font-size: 0.875rem;
    padding: 8px 16px;
    bottom: 25%; /* Ajustamos para que se vea mejor en móviles */
    left: 17%;
  }
  .heroLanding-button.right{
    left: 56%;
  }
}

/* Medium devices (tablets) */
@media (max-width: 991.98px) {
  .heroLanding-container {
    max-height: 300px;
  }
  .heroLanding-image {
    max-height: 300px;
  }
}

/* Large devices (desktops) */
@media (min-width: 992px) {
  .heroLanding-container {
    max-height: 600px;
  }
  .heroLanding-image {
    max-height: 600px;
  }
}
</style>