<template>
<section class="container-iconos py-3">
  <h2 class="subtitle text-center">¿POR QUÉ CONTRATAR NUESTROS SERVICIOS?</h2>
  <div class="row mt-4 justify-content-center ">
    <div class="col-lg-2 col-md-4 d-flex flex-column align-items-center mb-4 item-icono">
      <img class="icono icono-1" src="../assets/iconos/1 azul.svg" alt="icono-1">
      <span class="mt-3 text-center">Registro REPSE ARR2880/2024</span>
    </div>
    <div class="col-lg-2 col-md-4 d-flex flex-column align-items-center mb-4 item-icono">
      <img class="icono icono-2" src="../assets/iconos/2 azul.svg" alt="icono-2">
      <span class="mt-3 text-center">Personal calificado y confiable</span>
    </div>
    <div class="col-lg-2 col-md-4 d-flex flex-column align-items-center mb-4 item-icono">
      <img class="icono icono-3" src="../assets/iconos/3 azul.svg" alt="icono-3">
      <span class="mt-3 text-center">Capacitación del personal</span>
      <br>
    </div>
    <div class="col-lg-2 col-md-4 d-flex flex-column align-items-center mb-4 item-icono">
      <img class="icono icono-4" src="../assets/iconos/4 azul.svg" alt="icono-4">
      <span class="mt-3 text-center">Permiso Estatal y Federal de Seguridad Privada</span>
    </div>
    <div class="col-lg-2 col-md-4 d-flex flex-column align-items-center mb-4 item-icono">
      <img class="icono icono-5" src="../assets/iconos/5 azul.svg" alt="icono-5">
      <span class="mt-3 text-center">Pago 100% deducibles de impuestos</span>
    </div>
    <div class="col-lg-2 col-md-4 d-flex flex-column align-items-center mb-4 item-icono">
      <img class="icono icono-6" src="../assets/iconos/6 azul.svg" alt="icono-6">
      <span class="mt-3 text-center">Supervisión constante</span>
      <br>
    </div>
  </div>
</section>

    <!-- Estamos en REGLA iconos -->
    <!-- https://www.limpiar.mx/empresas/soluciones-integrales-de-personal-cmv-sc-de-p-de-rl-de-cv -->
<div class="container-regla py-4">
  <h2 class="subtitle text-center mb-4">
    ESTAMOS EN REGLA
  </h2>
  <div class="row justify-content-center align-items-center text-center">
    <div class="col-6 col-md-4 py-2">
      <img class="regla-icon" src="../assets/ESTAMOS EN REGLA/REPSE.svg" alt="REPSE">
    </div>
    <div class="col-6 col-md-3 py-2">
      <img class="regla-icon" src="../assets/ESTAMOS EN REGLA/CTPAT.svg" alt="CTPAT">
    </div>
    <div class="col-6 col-md-3 py-2">
      <img class="regla-icon" src="../assets/ESTAMOS EN REGLA/aespmich.svg" alt="AESPMICH">
    </div>
    <div class="col-6 col-md-3 py-2">
      <img class="regla-icon" src="../assets/ESTAMOS EN REGLA/CNSP.svg" alt="CNSP">
    </div>
    <div class="col-6 col-md-3 py-2">
      <a href="https://www.limpiar.mx/empresas/soluciones-integrales-de-personal-cmv-sc-de-p-de-rl-de-cv">
        <img class="regla-icon" src="../assets/ESTAMOS EN REGLA/Limpiarmx.png" alt="CNSP">
      </a>
      
    </div>
  </div>
</div>
</template>

<script>
</script>

<style>
.row{
    justify-content: space-between;
    align-items: center;
    text-align: center;
}

.container-iconos {
    background: linear-gradient(#EDEDED);
}
@media (max-width: 576px) {
  .item-icono img {
    max-width: 130px; /* Reduce el tamaño de los íconos */
  }
  .item-icono span {
    font-size: 0.9rem; /* Ajusta el tamaño del texto */
  }
}

.item-icono {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start; 
  height: 100%; 
  text-align: center;
}
.icono {
  max-width: 100%;
  height: 200px; 
  object-fit: contain; 
}
.item-icono span {
  font-size: 0.9rem; 
  text-align: center;
  line-height: 1.4; 
}

/* Subtítulo */
.subtitle {
  font-size: 1.8rem;
  font-weight: bold;
  color: #002169;
}

.mt-3{
  color: #002169;
}
.regla-icon {
  max-width: 100%;
  max-height: 100px;
  object-fit: contain;
}

.icon {
  fill: #002169;
  transition: fill 0.3s ease;
}

.icono-1:hover {
  content: url('../assets/iconos/1 amarillo.svg'); /* Imagen amarilla para el ícono 1 */
}

.icono-2:hover {
  content: url('../assets/iconos/2 amarillo.svg'); /* Imagen amarilla para el ícono 2 */
}

.icono-3:hover {
  content: url('../assets/iconos/3 amarillo.svg'); /* Imagen amarilla para el ícono 3 */
}

.icono-4:hover {
  content: url('../assets/iconos/4 amarillo.svg'); /* Imagen amarilla para el ícono 1 */
}

.icono-5:hover {
  content: url('../assets/iconos/5 amarillo.svg'); /* Imagen amarilla para el ícono 2 */
}

.icono-6:hover {
  content: url('../assets/iconos/6 amarillo.svg'); /* Imagen amarilla para el ícono 3 */
}





</style>